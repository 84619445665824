<template>
  <v-app>
  <form @submit.prevent="submit" ref="form">
  <v-stepper v-model="e6" vertical non-linear>
    <v-stepper-step :complete="e6 > 1" step="1" editable></v-stepper-step>
      <v-stepper-content step="1">
        <div class="stepWrapper">
          <FeelingIntake v-on:continue="continue1" button-text="continue" :cms-text="checkInFeelingContent" />
        </div>
      </v-stepper-content>
    
    <v-stepper-step :complete="e6 > 2" step="2" editable></v-stepper-step>
      <v-stepper-content step="2">
        <div class="stepWrapper">
          <ThoughtIntake v-on:continue="submit" button-text="submit" :cms-text="checkInThoughtContent"/>
        </div>
      </v-stepper-content>

    <!--<v-stepper-step :complete="e6 > 3" step="3" editable></v-stepper-step>
    <v-stepper-content step="3">
      <NextStepper />
    </v-stepper-content> -->
     </v-stepper>
    </form>
  </v-app>
</template>

<script>
    import '@/plugins/vuetify'
    import firebase from '@/firebase'
    import checkinModule from '@/store/checkin'
    import experienceModule from '@/store/experience'
    import statementModule from '@/store/statement'
    import cmsModule from '@/store/cms'
    import signinModule from '@/store/signin'
    import userModule from '@/store/user'
    import ThoughtIntake from '@/components/ThoughtIntake'
    //import NextStepper from '@/components/NextStepper'
    import FeelingIntake from '@/components/FeelingIntake'
    import { mapGetters, mapActions } from 'vuex'
    //import * as use from '@tensorflow-models/universal-sentence-encoder';

  export default {
    name: "Publish",
    components: {
      FeelingIntake,
      ThoughtIntake,
      //NextStepper
    },
    data () {
      return {
        e6: 1,
        notificationHistoryId: null,
        userUid: null,
        thoughtObj: null,
        actionStep: '',
        feelingObj: null,
        rules: [
          (v) => !!v || 'Statement is required',
          (v) => v.length <= 60 || 'Max 60 characters'
        ]
      }
    },
    computed: {
      ...mapGetters('cms', [
        'checkInFeelingContent',
        'checkInThoughtContent'
      ]),
    },
    methods: {
      
      ...mapActions('experience', [
        'setMinderExperienceState',
        'setShowCheckInCard'
      ]),
      ...mapActions('user', [
        'getUserState',
        'updateNotificationHistory'
      ]),
      ...mapActions('checkin', [
        'submitCheckIn',
        'resetCheckIn'
      ]),
      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('auth', [
        'setAutoSignInFlag',
        'setProfile',
        'setAnonymousProfile'
      ]),
      continue1 (feelingObj) {

          this.feelingObj = feelingObj
          this.e6 = 2
      },
      async submit (thoughtObj) {
        try {
          
            this.thoughtObj = thoughtObj
            this.e6 = 3
            var checkInObj = {
              'thoughtObj': thoughtObj,
              'feelingObj': this.feelingObj
            }


            //check for logged in user
            var unsubscribe = firebase.auth().onAuthStateChanged(async(user) =>{   //Commenting out this code stopped multiple Minders from being created.  I could unsubscribe.
              //var user = firebase.auth().currentUser;  //This line works, but want to see if I can unsubscribe
              unsubscribe()
              var acctType = this.$store.state.auth.profile.accountType
              var loggedInBool = this.$store.state.auth.loggedIn
              if(!user) {
                //console.log("Submitting checkin, no user")
                if(loggedInBool === true) {
                  //console.log("loggedInBool equals true")
                  //heediUser
                  if(acctType === 'heediUser') {
                    //console.log("acctType is heediUser")
                    await this.googleLogin()
                  }
                  //anonymous
                  else if(acctType === 'anonymous') {
                    //console.log("acctType is anonymous")
                    await this.anonymousLogin()
                  }
                  this.submitCheckIn(checkInObj)
                  //this.setMinderExperienceState({state:'checkedIn', userUid: this.$store.state.auth.profile.uid})
                  this.setShowCheckInCard(true)
                  this.$router.push('/console')
                }

              } else {
                //console.log("Submitting checkin, there is a user")
                //heediUser
                if(acctType == 'heediUser') {
                  //console.log("acctType is heediUser")
                  await this.setProfile(user)
                }
                //anonymous
                if(acctType == 'anonymous') {
                  //console.log("acctType is anonymous")
                  await this.setAnonymousProfile(user)
                }
                await this.getUserState(user.uid)
                this.submitCheckIn(checkInObj)
                //this.setXpState({state:'checkedIn', userUid: this.$store.state.auth.profile.uid})
                this.setShowCheckInCard(true)
                this.$router.push('/console')

                //configure Google Analytics with user uid
                //gtag('config', 'G-H9RD8W4MLR', {
                //  'user_id': user.uid
                //})
              }
            })

            //gtag('event', 'checked_in') 

        } catch (error) {
          alert(error)
        }
      },
      
    },
    beforeCreate () {
      if(!this.$store.state.cms) this.$store.registerModule('cms', cmsModule)
      if(!this.$store.state.checkin) this.$store.registerModule('checkin', checkinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.statement) this.$store.registerModule('statement', statementModule)
    },
    created () {
      //this.resetCheckIn()
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/check-in'});
      this.autoSignIn()
      
    },
    mounted () {
      this.notificationHistoryId = this.$route.params.notifId
      //console.log("notificationHistoryId: "+ this.notificationHistoryId)
      this.userUid = this.$route.params.userUid
      //console.log("userUid: "+ this.userUid)
      this.updateNotificationHistory({'notifId':this.notificationHistoryId, 'userUid': this.userUid})
    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>


.stepWrapper {
  max-width: 700px;
  margin: 0 auto;
}
</style>
